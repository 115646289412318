import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  category?: string
  image?: ImageProps
  languageCode: string
  title: string
  URL?: string
  validity?: string
  weight?: number
}

export const Offer = memo(function Offer({
  category,
  image,
  languageCode,
  title,
  URL,
  validity,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {category ? <Category>{category}</Category> : null}
        <Title>{title}</Title>
        <Button
          label={useVocabularyData('details', languageCode)}
          URL={URL ? URL : ''}
          variant="corners"
        />
      </Wrapper>
      <SecondaryImage>
        {image ? (
          <Image
            className="swiper-lazy"
            style={{ backgroundImage: `url(${image?.src})` }}
          />
        ) : null}
      </SecondaryImage>
      <MainImage>
        {image ? (
          <Image
            className="swiper-lazy"
            style={{ backgroundImage: `url(${image?.src})` }}
          />
        ) : null}
        {validity ? (
          <Validity>{`${useVocabularyData(
            'valid-offer',
            languageCode,
          )} ${validity}`}</Validity>
        ) : null}
      </MainImage>
    </Container>
  )
})

const Container = styled.div`
  padding: 3.75rem 0;
  position: relative;

  @media (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
  }
`

const MainImage = styled.div`
  width: 65%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 767px) {
    width: 100%;
    position: relative;
    height: auto;
    padding-bottom: 66%;
  }
`

const SecondaryImage = styled.div`
  width: calc(35% - 5.3125rem);
  height: calc(100% - 3.75rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  overflow: hidden;
  position: absolute;
  top: 1.875rem;
  left: 5.3125rem;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryDark1};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: 0.3s ease-in-out;
  }

  @media (max-width: 1023px) {
    width: calc(35% - 1.5rem);
    left: 1.5rem;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Image = styled.span`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;
`

const Validity = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  letter-spacing: 2px;
  line-height: 1.125rem;
  position: absolute;
  top: 1.875rem;
  right: 1.875rem;
  z-index: 2;
  text-transform: uppercase;

  @media (max-width: 767px) {
    right: 1.5rem;
    left: 1.5rem;
  }
`

const Wrapper = styled.div`
  width: 40%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 5.375rem;
  position: relative;
  z-index: 3;
  text-align: left;

  @media (max-width: 1023px) {
    padding: 3.75rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    order: 2;
    padding: 3rem 1.5rem;
  }
`

const Category = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.125rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
`
