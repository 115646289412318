import 'swiper/swiper-bundle.min.css'

import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Separator } from 'app/components/Common/Separator'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import * as Icon from 'react-feather'
import Fade from 'react-reveal/Fade'
import SwiperCore, {
  Autoplay,
  Controller,
  EffectFade,
  Lazy,
  Navigation,
  Thumbs,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Offer, Props as OfferProps } from './Offer'

SwiperCore.use([Autoplay, Controller, EffectFade, Lazy, Navigation, Thumbs])

export interface Props {
  cta?: string
  description?: string
  languageCode: string
  offers: OfferProps[]
  title?: string
}

export const OffersSlider = memo(function OffersSlider({
  cta,
  description,
  languageCode,
  offers,
  title,
}: Props) {
  if (offers.length < 1) {
    return null
  }

  return (
    <Container>
      <Separator />
      {title ? (
        <Fade bottom>
          <Title>{title}</Title>
        </Fade>
      ) : null}
      {description ? (
        <Fade bottom>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </Fade>
      ) : null}
      <Fade bottom distance="3.75rem">
        <Slider>
          {offers ? (
            <>
              <Swiper
                allowTouchMove={false}
                autoplay={{ delay: 4000 }}
                effect="fade"
                lazy
                loop={offers.length > 1 ? true : false}
                navigation={{
                  nextEl: `.offers-slider-next`,
                  prevEl: `.offers-slider-prev`,
                }}
                preloadImages={false}
                watchSlidesProgress
              >
                {offers.map((item, index) => (
                  <SwiperSlide key={index}>
                    <Offer {...item} />
                  </SwiperSlide>
                ))}
              </Swiper>
              {offers.length > 1 ? (
                <>
                  <SliderControls row space="between">
                    <Arrow dial={5} className={`offers-slider-prev`}>
                      <Icon.ChevronLeft />
                    </Arrow>
                    <Arrow dial={5} className={`offers-slider-next`}>
                      <Icon.ChevronRight />
                    </Arrow>
                  </SliderControls>
                </>
              ) : null}
            </>
          ) : null}
        </Slider>
      </Fade>
      {cta ? (
        <Button
          label={useVocabularyData('all-offers', languageCode)}
          URL={cta}
          variant="simple"
        />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  max-width: 1114px;
  margin: 6rem auto 9.75rem;
  padding: 0 1.5rem;
  text-align: center;

  @media (max-width: 1023px) {
    margin: 3.75rem auto 7.5rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
`

const Description = styled.div`
  max-width: 32.5rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
  margin: 0.75rem auto 0;
`

const Slider = styled.div`
  margin-top: 3.75rem;
  position: relative;
  .swiper-slide {
    height: auto;
  }
`

const SliderControls = styled(FlexBox)`
  position: absolute;
  bottom: 2.25rem;
  right: 2.25rem;
  z-index: 2;

  @media (max-width: 767px) {
    display: none;
  }
`

const Arrow = styled(FlexBox)`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  margin-left: 24px;
  overflow: hidden;
  position: relative;
  svg {
    width: auto;
    height: 5.375rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
    stroke-width: 0.4;
    position: absolute;
    top: -1.25rem;
    left: -1.25rem;
  }
`
